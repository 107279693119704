import React from 'react'
//component.css

const Footer = () => {
  return (
      <div className='part2'>
        <div>&copy; 2024 All rights reserved</div>
        <div>support@vsmartengine.com</div>
        <div>Ph : 91-9566191759</div>
        <div>learnhubdigital.vsmartengine.com</div>
      </div>
   
  )
}

export default Footer
